<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Proveedores</h4>
            <div class="small text-muted">Vista detallada del proveedor seleccionado</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="4">
          <b-card>
            <b-row>
              <b-col>
                <b-avatar :src="suppliers.image"
                          v-if="suppliers.image">
                </b-avatar>
                <b-avatar v-else         
                          icon="building"                                    
                          variant="dark">
                </b-avatar>     
                &nbsp;<b>{{suppliers.name}}</b>     
              </b-col>
            </b-row>

            <b-row>
              <b-col>                    
                
                <div class="ml-5">
                  <div v-if="!suppliers.email">
                    <b-icon icon="envelope"></b-icon> &nbsp;&nbsp;
                    <b-badge variant="warning" class="badget-custom">No tiene un email asociado</b-badge>                                                    
                  </div>
                  <div v-else>
                    <b-icon icon="envelope"></b-icon> &nbsp;&nbsp;
                    <b-link :href="'mailto:' + suppliers.email">
                      {{ suppliers.email }}
                    </b-link> 
                  </div>
                </div>
                
                <div class="ml-5" v-if="suppliers.phone">
                  <b-icon icon="phone"></b-icon> &nbsp;&nbsp;
                  <b-link :href="'tel:' + suppliers.phone">
                    {{ suppliers.phone }}
                  </b-link>               
                </div>                    

              </b-col>
            </b-row>

            <b-row v-if="suppliers.observations">
              <b-col>
                <hr>
                {{ suppliers.observations }}
              </b-col>
            </b-row>
          </b-card>      
        </b-col>  

        <b-col md="8">
          <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark"                               
                  v-if="showTab">
            <b-tab title="Datos Fiscales" v-if="this.configTabs.fiscal.show" :active="tabs.fiscal" @click="setActiveTab('fiscal')">
                <tab-fiscal v-if="isSuppliersSales" :suppliers="suppliers" :suppliersID="suppliersID"></tab-fiscal>
            </b-tab>

            <b-tab title="Cuenta Corriente" v-if="this.configTabs.currentAccount.show" :active="tabs.currentAccount" @click="setActiveTab('currentAccount')">
              <tab-currentAccount :suppliers="suppliers" :suppliersID="suppliersID"></tab-currentAccount>
            </b-tab>

            <b-tab title="Cuenta Contable" v-if="this.configTabs.accountingAccount.show" :active="tabs.accountingAccount" @click="setActiveTab('accountingAccount')">
              <tab-accountingAccount :suppliers="suppliers" :suppliersID="suppliersID"></tab-accountingAccount>
            </b-tab>

          </b-tabs>
          <b-alert v-else show variant="warning">
            No se encotró información de módulos relacionados.
          </b-alert>
        </b-col>        
      </b-row>

      <b-card class="mt-2" header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>  

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import Storage from '@/handler/storageLocal'
  import TabFiscal from './tabs/fiscal'
  import TabAccountingAccount from './tabs/accountingAccount'
  import TabCurrentAccount from './tabs/currentAccount'

  export default {
    components: {
      Widget,
      TabFiscal,
      TabAccountingAccount,
      TabCurrentAccount,
    },
    data: () => {
      return {
        access: {
          module_id: Modules.PROVEEDORES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'view',
          elements: {}
        },
        configTabs: {
          fiscal: {
            show: false,
          },
          accountingAccount: {
            show: false,
          },
          currentAccount: {
            show: false,
          },          
        },
        configWidget: {
          module_id: Modules.PROVEEDORES,
          profile_id: Profiles.PERSONAL,
          elements: {}
        },
        primaryColor: '',
        suppliersID: 0,
        suppliers: {},
        modulesActive: [],
        tabs: {
          fiscal: true,
          accountingAccount: false,
          currentAccount: false,
        }
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },
    mounted() {
      this.suppliersID = this.$route.params.suppliersID
      this.modulesActive = Session.getSession().auth.user.permissions.modules

      this.setup()
      this.load()
      this.configAccess()

      this.restoreStatusTabsStorage()
    },
    computed: {
      showTab () {
        var status = false
        if(this.configTabs.fiscal.show ||           
           this.configTabs.currentAccount.show ||
           this.configTabs.accountingAccount.show) {
          status = true
        }
        return status
      },    
      isSuppliersSales() {  
        if(this.suppliers.data_fiscal) {
          return true
        } else {
          if(this.suppliers.data_fiscal==undefined) {
            return true
          } else {
            return false
          }
        }
      },
    },
    methods: {
      load() {
        let loader = this.$loading.show();
        var result = serviceAPI.mostrar(this.suppliersID)

        result.then((response) => {
          var data = response.data
          this.suppliers = data          
          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },            
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      },

      configAccess() {        
        this.modulesActive.forEach(element => {
          if(element.id == Modules.COMPRAS) {
            this.configTabs.fiscal.show = true
            this.configTabs.currentAccount.show = true
          } 

          if(element.id == Modules.CONTABILIDAD) {
            this.configTabs.accountingAccount.show = true
          } 
        })
      },

      //TABS
      disableAllTabs() {
        this.tabs.fiscal = false
        this.tabs.accountingAccount = false
        this.tabs.currentAccount = false
      },
      setActiveTab(value) {
        this.disableAllTabs()

        switch (value) {
          case 'fiscal':
            this.tabs.fiscal = true
            break;

          case 'accountingAccount':
            this.tabs.accountingAccount = true
            break;

          case 'currentAccount':
            this.tabs.currentAccount = true
            break;        

          default:
            this.tabs.fiscal = true
            break;
        }

        this.saveStatusTabsStorage()
      },

      // FILTROS SAVE
      saveStatusTabsStorage() {
        Storage.setValue('tabs_view_supplier', JSON.stringify(this.tabs))
      },
      restoreStatusTabsStorage() {
        if(Storage.getValue('tabs_view_supplier')) {
          this.tabs = JSON.parse(Storage.getValue('tabs_view_supplier'))
        }         
      }, 
    }    
   
  }
</script>
